import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Page404, ContentPage, Layout, ScrollToTop } from "@vaccinessites/components";
import { CMSService } from "@vaccinessites/services";
import {config} from './config';
import './ghdc.scss';

let defaultContent = require('./content/contentful-content.json');
let CMS= CMSService.initialize(defaultContent);

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop/>
        <Layout CMS={CMS} site={'ghdc'}
          navOptions={{
            portalLogin: config.portalLogin,
            loginHelp: config.loginHelp,
            logoPlacement: 'center'
          }}>
          <Switch>
            <Route exact path="/"><ContentPage CMS={CMS} config={config}/></Route>            
            <Route path="/about/:slug"><ContentPage CMS={CMS} config={config}/></Route>
            <Route exact path="/about"><ContentPage CMS={CMS} config={config}/></Route>
            <Route path="/programs/:slug"><ContentPage CMS={CMS} config={config}/></Route>
            <Route exact path="/programs"><ContentPage CMS={CMS} config={config}/></Route>
            <Route path="/platforms/:slug"><ContentPage CMS={CMS} config={config}/></Route>
            <Route exact path="/platforms"><ContentPage CMS={CMS} config={config}/></Route>
            <Route path="/technologies/:slug"><ContentPage CMS={CMS} config={config}/></Route>
            <Route exact path="/technologies"><ContentPage CMS={CMS} config={config}/></Route>
            <Route path="/data-access-and-integration/:slug"><ContentPage CMS={CMS} config={config}/></Route>
            <Route exact path="/data-access-and-integration"><ContentPage CMS={CMS} config={config}/></Route>            
            <Route path="/app/:slug"><ContentPage CMS={CMS} config={config}/></Route>
            <Route exact path="/app"><ContentPage CMS={CMS} config={config}/></Route>
            <Route><Page404/></Route>
          </Switch>
        </Layout>
      </Router>
    </div>
  );
}

export default App;

