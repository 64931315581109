export const CAVD_PUBS_SORTS = [{
    label: 'Grantee',
    id: "CAVDGrantee",
    values: [{id:"grantee-ascending", label: 'Ascending', value:"asc" }, 
        {id:"grantee-descending",label: 'Descending', value:'desc'}]
},{
    label: 'Publication Date',
    id: "PublishDate",
    values: [{id:"pubdate-ascending", label: 'Ascending', value:"asc" }, 
        {id:"pubdate-descending",label: 'Descending', value:'desc'}]
},{
    label: 'Journal Name',
    id: "JournalName",
    values: [{id:"journal-ascending", label: 'Ascending', value:"asc" }, 
        {id:"journal-descending",label: 'Descending', value:'desc'}]
}]

export const CAVD_PUBS_COLS = [
    {label: "Title", id: 'title', isPublicationsModal: true},
    {label: "Grantee", id: 'cavdGrantee'},
    {label: "Authors", id: 'authors'},
    {label: "Journal Name", id: 'journalName'},
    {label: "Date", id: 'publishDate', isDate: true},                
    {label: "PMID", id: 'pmid'},
    {label: "Link", id: 'linkToFullText', isLink: true},
];

export const GHDC_PUBS_SORTS = [{
    label: 'Publication Year',
    id: "PublicationYear",
    values: [{id:"pubdate-ascending", label: 'Ascending', value:"asc" }, 
        {id:"pubdate-descending",label: 'Descending', value:'desc'}]
}]

export const GHDC_PUBS_COLS = [
    {label: "Title", id: 'title'},
    {label: "Authors", id: 'authors'},
    {label: "Journal Name", id: 'journalName'},
    {label: "Publication Year", id: 'publicationYear'},                
    {label: "PMID", id: 'pmid'},
    {label: "Link", id: 'link', isLink: true},
];

export const CAVD_STUDY_SORTS = [{
    label: 'Released to Public',
    id: "ReleasedToPublic",
    values: [{id:"released-ascending", label: 'Ascending', value:"asc" }, 
        {id:"released-descending",label: 'Descending', value:'desc'}]
},{
    label: 'CAVD Affiliation',
    id: "CAVDAffiliation",
    values: [{id:"affiliation-ascending", label: 'Ascending', value:"asc" }, 
        {id:"affiliation-descending",label: 'Descending', value:'desc'}]
}]

export const CAVD_STUDY_COLS = [
    {label: "CAVD Affiliation", id: 'cavdAffiliation'},
    {label: "Public Study Title", id: 'publicStudyTitle', isStudyResultsModal: true},
    {label: "Strategic Initiative", id: 'strategicInitiative'},
    {label: "Unique ID", id: 'uniqueID'},
    {label: "Recipient CSF", id: 'recipientCSF'},                
    {label: "Released to Public", id: 'releasedToPublic', isDate: true},
    {label: "Link", id: 'studyResultsLink', isLink: true},
];